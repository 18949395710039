@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
  font-family: "RussoOne-Regular";
  src: url("fonts/RussoOne-Regular.ttf");
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
text:-webkit-autofill,
text:-webkit-autofill:hover,
text:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 9999s ease-in-out 0s;
} */

.nopage {
  z-index: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.nopage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reactive-btn-wrapper,
.reactive-btn {
  --reactive-button-min-width: 0px !important;
  --reactive-button-min-height: 35px !important;
  --reactive-button-font-weight: 400 !important;
  --reactive-button-border-radius: 0px !important;
  --reactive-button-text-color: rgb(
    255 255 255 / var(--tw-bg-opacity)
  ) !important;
  --reactive-progress-color: rgba(0, 0, 0, 0.15) !important;
  --reactive-button-primary-color: rgb(
    141 0 222 / var(--tw-bg-opacity)
  ) !important;
  --reactive-progress-outline-primary-color: rgba(88, 103, 221, 0.3) !important;
}

.reactive-btn-wrapper:hover,
.reactive-btn:hover {
  opacity: 1;
}

body {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
body::-webkit-scrollbar {
  display: none;
}
